import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'; 
import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
 
const routes: Routes = [
  {
      path: '',
      component: PagesComponent, children: [            
           { path: '', redirectTo: '/', pathMatch: 'full' },
           { path: '', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },
           { path: 'experiencia', loadChildren: () => import('./pages/experience/experience.module').then(m => m.ExperienceModule) },
           { path: 'conocimientos', loadChildren: () => import('./pages/skills/skills.module').then(m => m.SkillsModule) },
           { path: 'educacion', loadChildren: () => import('./pages/education/education.module').then(m => m.EducationModule) },
           { path: 'portfolio', loadChildren: () => import('./pages/portfolio/portfolio.module').then(m => m.PortfolioModule) }, 
           { path: 'robot', loadChildren: () => import('./pages/robot/robot.module').then(m => m.RobotModule) },
           { path: 'contacto', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule) }
      ]
  },
  //{ path: 'inicio', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      initialNavigation: 'enabled', // for one load page, without reload
      relativeLinkResolution: 'legacy',
      useHash: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
