<mat-toolbar color="primary" class="horizontal-menu" ngClass.gt-sm="px-0">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxHide="false" fxHide.gt-sm class="w-100"> 
        <button mat-icon-button (click)="drawer.toggle()"><mat-icon>menu</mat-icon></button>
        <span class="person-name">Alfonso Pardo</span>
    </div>
    <div fxShow="false" fxShow.gt-sm class="container"> 
        <app-menu></app-menu>
    </div>
</mat-toolbar>

<mat-drawer-container>
    <mat-drawer #drawer mode="over" class="sidenav" autoFocus="false">
        <div [perfectScrollbar]="" class="h-100">
            <app-menu></app-menu>
        </div>
    </mat-drawer>    

    <mat-drawer-content>
        <div class="wrapper">
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>

        <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition" (click)="scrollToTop()">
            <mat-icon>arrow_upward</mat-icon>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
